/* Color Variables */

:root {
  --iah-red: #b84547;
  --iah-red-light: #c28384;
  --iah-yellow: #e7ea81;
  --iah-green: #a5d27c;
  --iah-blue: #2a8bb9;
  --iah-light: #f1f8ed;
  --iah-grey-light: #dad9d9;
  --iah-grey-med: #a6a5a5;
  --iah-grey-dark: #383838;

  --cs1-grey-dark: #383838;
  --cs1-yellow-dark: #6b6b5c;
  --cs1-red-light: #c28384;
  --cs1-red: #b84547;
  --cs1-yellow: #e7ea81;
  --cs1-yellow-light: #ededc9;

  --cs2-grey-dark: #383838;
  --cs2-green-dark: #739156;
  --cs2-green: #a5d27c;
  --cs2-yellow: #e7ea81;
  --cs2-yellow-light: #ededc9;
  --cs2-light: #f1f8ed;

  --cs3-grey-dark: #383838;
  --cs3-yellow-dark: #6b6b5c;
  --cs3-blue: #2a8bb9;
  --cs3-yellow: #e7ea81;
  --cs3-yellow-light: #ededc9;
  --cs3-light: #f1f8ed;

  --cs4-grey-dark: #383838;
  --cs4-orange: #ea703e;
  --cs4-red: #b84547;
  --cs4-blue: #2a8bb9;
  --cs4-blue-light: #62c5f0;
  --cs4-light: #f1f8ed;

  --cs5-grey-dark: #383838;
  --cs5-green-dark: #739156;
  --cs5-green: #a5d27c;
  --cs54-blue: #2a8bb9;
  --cs5-purple: #97a1d1;
  --cs5-light: #f1f8ed;
}
